import React, { useEffect, useState } from 'react';
import FloorPlan from './components/FloorPlan';
import './App.css';
import ModelViewer from './components/ModelViewer';

function App() {
  const [selectedRoom, setSelectedRoom] = useState(null);

  useEffect(() => {
    console.log("Seçilen oda değişti:", selectedRoom);
  }, [selectedRoom]);

  const handleRoomClick = (room_id) => {
    console.log("Oda seçildi:", room_id);
    setSelectedRoom(room_id);
    // Burada tıklanan odanın bilgisini alın ve setSelectedRoom ile durumu güncelleyin
  };

  return (
    <div className="App">
      {selectedRoom && <button className='backButton' onClick={() => setSelectedRoom(null)}>Geri</button>}
      {/* ModelViewer veya FloorPlan */}
      {selectedRoom ? <ModelViewer modelPath="/model/scene.gltf" selectedRoom={selectedRoom} /> : <FloorPlan onRoomClick={handleRoomClick} />}
    </div>
  );
}

export default App;
