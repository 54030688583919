import React, { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';
import { FontLoader, GLTFLoader, OrbitControls, TextGeometry } from 'three-stdlib';


function ModelViewer({ modelPath, selectedRoom }) {
    const canvasRef = useRef();
    const [loaded, setLoaded] = useState(false);
    //progress
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        // Sahne, kamera ve renderer'ı oluşturma
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        camera.position.set(
            -62.08297042748363,
            7.631919952226225,
            13.762358178907782
        );

        // sahne arkaplan rengi
        scene.background = new THREE.Color(0xffffff);


        const renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setSize(window.innerWidth, window.innerHeight);
        canvasRef.current.appendChild(renderer.domElement);

        const controls = new OrbitControls(camera, renderer.domElement);
        controls.enableDamping = true; // İnce hareketler için
        controls.dampingFactor = 0.05;
        // Varsayılan kamera hedefini ayarla
        controls.target.set(
            -43.346174995978785,
            -1.6628476294072008,
            0.4163471237990841
        );
        // Damping özelliğini etkinleştir
        controls.enableDamping = true;

        // Genel ortam ışığı
        const ambientLight = new THREE.AmbientLight(0xffffff, 5);
        scene.add(ambientLight);

        // Nokta ışığı
        const pointLight = new THREE.PointLight(0xffffff, 115, 500);
        pointLight.position.set(0, 10, 0); // Işık kaynağının konumunu ayarlayın
        scene.add(pointLight);

        // Nokta ışığı
        const pointLight2 = new THREE.PointLight(0xffffff, 15, 500);
        pointLight2.position.set(5, 10, 0); // Işık kaynağının konumunu ayarlayın
        scene.add(pointLight2);

        // Nokta ışığı
        const pointLight3 = new THREE.PointLight(0xffffff, 15, 500);
        pointLight3.position.set(-5, 10, 0); // Işık kaynağının konumunu ayarlayın
        scene.add(pointLight3);

        // Yönlü ışık
        const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
        directionalLight.position.set(-5, 5, 5);
        scene.add(directionalLight);

        // Modeli yükleme
        const loader = new GLTFLoader();
        loader.load(modelPath, (gltf) => {
            scene.add(gltf.scene);
            gltf.scene.position.set(0, 90, -20);
            setLoaded(true);
        }, (progress) => {
            console.log("Yükleniyor:", progress);
            setProgress(progress.loaded);
        }, (error) => {
            console.error('Model yükleme hatası:', error);
        });

        // add text to the scene
        const loader2 = new FontLoader();
        loader2.load('https://threejsfundamentals.org/threejs/resources/threejs/fonts/helvetiker_regular.typeface.json', function (font) {
            const geometry = new TextGeometry(selectedRoom, {
                font: font,
                size: 0.8,
                height: 0.2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 5
            });
            const material = new THREE.MeshPhongMaterial({ color: 0xff0000 });
            const mesh = new THREE.Mesh(geometry, material);
            mesh.rotateY(-1);
            mesh.position.set(-43.346174995978785, 7.6628476294072008, 0.4163471237990841);
            scene.add(mesh);
        });

        const loader3 = new FontLoader();
        loader3.load('https://threejsfundamentals.org/threejs/resources/threejs/fonts/helvetiker_regular.typeface.json', function (font) {
            const geometry = new TextGeometry('You can use mouse and ctrl key', {
                font: font,
                size: 0.5,
                height: 0.2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 5
            });
            const material = new THREE.MeshPhongMaterial({ color: 0x8b123d });
            const mesh = new THREE.Mesh(geometry, material);
            mesh.rotateY(-1);
            mesh.position.set(-43.346174995978785, 5.9628476294072008, 0.4163471237990841);
            scene.add(mesh);
        });

        const loader4 = new FontLoader();
        loader4.load('https://threejsfundamentals.org/threejs/resources/threejs/fonts/helvetiker_regular.typeface.json', function (font) {
            const geometry = new TextGeometry('Mavvo Bilisim', {
                font: font,
                size: 0.5,
                height: 0.2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 5
            });
            const material = new THREE.MeshPhongMaterial({ color: 0x623718 });
            const mesh = new THREE.Mesh(geometry, material);
            mesh.rotateY(-1);
            mesh.position.set(-43.346174995978785, 4.9628476294072008, 0.4163471237990841);
            scene.add(mesh);
        });


        // log camera position on camera change
        controls.addEventListener('change', () => {
            // console.log(camera.position);
        });



        // Animasyon döngüsü
        const animate = () => {
            requestAnimationFrame(animate);
            controls.update();
            renderer.render(scene, camera);
        };

        animate();

        // Pencere boyut değişikliklerini işleme
        const handleResize = () => {
            const width = window.innerWidth;
            const height = window.innerHeight;
            renderer.setSize(width, height);
            camera.aspect = width / height;
            // camera.updateProjectionMatrix();
        };

        // window.addEventListener('resize', handleResize);

        // Temizleme fonksiyonu
        return () => {
            window.removeEventListener('resize', handleResize);
            // canvasRef.current.removeChild(renderer.domElement);
        };
    }, [modelPath]);

    return (
        <>
            {!loaded && <div id="loading-bar-spinner" className="spinner"><div className="spinner-icon"></div></div>}
            <div className={`${!loaded && 'd-none'} canvas`} ref={canvasRef}></div>
        </>
    );
}

export default ModelViewer;
