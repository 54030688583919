import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import 'maphilight';

function FloorPlan({ onRoomClick }) {
    const mapRef = useRef(null);

    useEffect(() => {
        if (mapRef.current) {
            $(mapRef.current).maphilight();
        }
    }, [mapRef]);
    return (
        <div className="floor-plan">
            <map name="sketchMap" className='sketchMap'>
                <area alt="Office 1" title="Office 1 - 11'-0° X 12-10°" href="#" coords="43,53,41,209,177,210,175,54" shape="poly" onClick={() => {
                    onRoomClick("Office 1 - 11'-0° X 12-10°");
                }} />
                <area alt="Hvac" title="SUPPLY/HVAC" href="#" coords="178,52,179,151,272,150,271,54" shape="poly" onClick={() => {
                    onRoomClick("SUPPLY/HVAC");
                }} />
                <area alt="Office 2" title="Office 2 - 8'-11' X 12'-10" href="#" coords="276,53,384,54,383,210,274,209" shape="poly" onClick={() => {
                    onRoomClick("Office 2 - 8'-11\" X 12'-10\"");
                }} />
                <area alt="Office 3" title="Office 3 - 8'-11 X 12'-10'" href="#" coords="388,52,387,209,496,209,496,53" shape="poly" onClick={() => {
                    onRoomClick("Office 3 - 8'-11 X 12'-10'");
                }} />
                <area alt="Office 4" title="Office 4 - 11'-0 X 12'-8" href="#" coords="43,216,43,370,174,370,176,217" shape="poly" onClick={() => {
                    onRoomClick("Office 4 - 11'-0 X 12'-8");
                }} />
                <area alt="Office 5" title="Office 5 - 11-7✕8-0" href="#" coords="292,275,291,370,433,370,431,275" shape="poly" onClick={() => {
                    onRoomClick("Office 5 - 11-7✕8-0");
                }} />
                <area alt="Office 6" title="Office 6 - 11-11X8-0" href="#" coords="499,273,498,371,644,370,644,273" shape="poly" onClick={() => {
                    onRoomClick("Office 6 - 11-11X8-0");
                }} />
                <area alt="Office 7" title="Office 78-11X12-8" href="#" coords="647,216,648,370,756,370,756,216" shape="poly" onClick={() => {
                    onRoomClick("Office 78-11X12-8");
                }} />
            </map>
            <img src="/plan.png" useMap="#sketchMap" alt="Floor Plan" ref={mapRef} />
        </div>
    );
}

export default FloorPlan;
